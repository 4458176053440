#Appointment {
  position: relative;
  height: 100%;

  #Filter,
  #Map {
    top: $HEADER_HEIGHT;
    position: fixed;
    z-index: 2;
  }

  #Filter {
    width: 40%;
    right: 0;
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-left-width: 0;
    border-right-width: 0;
    height: $MOBILE_VIEW_SWITCH_HEIGHT;
    background-color: white;

    .react-datepicker {
      left: 30px;
    }
  }

  #Map {
    width: 60%;
    left: 0;
    z-index: 1;
    height: calc(100vh - #{$HEADER_HEIGHT});
    position: fixed;
    display: block;
  }

  #Contents {
    background-color: white;
    height: 100%;
    top: calc($MOBILE_VIEW_SWITCH_HEIGHT + $HEADER_HEIGHT);
    position: fixed;
    width: 40%;
    right: 0;
    -webkit-overflow-scrolling: touch;
  }

  .place-card {
    padding: 1.25rem;
    .is-pin-active {
      color: $MAP_PIN_COLOR !important;
    }
  }

  @include mixins.mobile {
    #Filter,
    #Map,
    #Contents {
      width: 100%;
    }

    #Map {
      &.is-mapview {
        left: 0;
      }

      &.is-listview {
        left: -100%;
      }
    }

    #Contents {
      &.is-mapview {
        display: none;
      }

      &.is-listview {
        display: block;
      }
    }
  }
}
