$HEADER_HEIGHT: 64px;
$MOBILE_VIEW_SWITCH_HEIGHT: 41px;
$MAP_PIN_COLOR: #e20000;

@use "bulma/sass/utilities/mixins";

// @import "bulma/sass/helpers/visibility";
// @import "bulma/sass/helpers/typography";
// @import "bulma/sass/utilities";
// @import "bulma/sass/themes/light";
// @import "bulma/sass/themes/setup";
// @import "bulma/sass/elements";
// @import "bulma/sass/form";
// @import "bulma/sass/components";
// @import "bulma/sass/grid";
// @import "bulma/sass/layout";
// @import "bulma/sass/base/skeleton";

@import "bulma/sass/layout/container";
@import "bulma/sass/layout/section";
@import "bulma/sass/layout/hero";
@import "bulma/sass/base/minireset";
@import "bulma/sass/helpers";

@import "appointment.scss";

.hero {
  @include mixins.mobile {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.col-content {
  @include mixins.mobile {
    height: 200px;
  }
}

/* Content padding for fixed nav */
.content-wrapper {
  padding-top: $HEADER_HEIGHT;
}

.section.is-thin {
  @include mixins.mobile {
    padding-top: 1.5rem;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* buttons for carousel */
.slide-buttons {
  .slide-button {
    background-color: transparent !important;
    border: 0 !important;
    color: #666666;
    height: 100%;
    position: absolute;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: white !important;
    }
    &.is-left {
      top: 0;
      left: 0;
    }
    &.is-right {
      top: 0;
      right: 0;
    }
  }
}

/* antd image object fit */
.ant-image-img {
  object-position: center;
  object-fit: cover;
  width: 100%;
}

.ant-list-item-meta-title {
  margin-bottom: 0 !important; /* Adjust globally */
}

/* important to prevent to close the modal when clicking on the overlay */
.ant-image-preview-img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* parallax banner on salon pages */
.parallax-banner-container {
  .parallax-banner-text {
    width: 100%;
    position: absolute;
    display: flex;
    inset: inherit;
    align-items: center;
    justify-content: center;
  }

  .parallax-banner-img {
    background-size: cover;
    background-position: center;
  }
  &,
  .parallax-banner-img {
    height: 280px;
    @include mixins.mobile {
      height: 150px;
    }
  }
}

/* for stripe card element */
.stripe-card-element-container {
  height: 32px;
  padding: 6px 11px;
  list-style: none;
  position: relative;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

// important for custom datepicker input
.react-datepicker-wrapper {
  width: 100%;
}
